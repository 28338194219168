<template>
  <div>
    <v-dialog v-model="dialog" :overlay="false" max-width="500px" transition="dialog-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" v-show="modulePermission('baabd', 'create')" @click="getData(true)">Add</v-btn>
      </template>
      <v-card color="" :loading="loading">
        <v-toolbar dense elevation="0">
          <v-toolbar-title>Approval Template</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="getData(true)"> <v-icon>mdi-refresh</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-model="search" dense flat hide-details outlined placeholder="Search" clearable></v-text-field>
        </v-card-text>
        <v-card-text class="pa-0" style="max-height: 300px; overflow-y: auto">
          <v-list class="pb-4 px-2">
            <v-list-item-group v-model="selectedDocument">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="item.document_type"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="item.area_code"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="createDocument()" :disabled="selectedDocument == null" :loading="loading_create">Create Document</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      loading: false,
      loading_create: false,
      dialog: false,
      selectedDocument: null,
      approval_templates: [],
    };
  },

  computed: {
    items() {
      if (this.search == "") return this.approval_templates;

      var res = this.approval_templates.filter((item) => {
        return item.document_type.toString().toLowerCase().includes(this.search.toString().toLowerCase()) || item.description.toString().toLowerCase().includes(this.search.toString().toLowerCase()) || item.area_code.toString().toLowerCase().includes(this.search.toString().toLowerCase());
      });
      return res;
    },
  },

  methods: {
    async getData(refresh = false) {
      this.loading = true;
      if (refresh == true) this.AxiosStorageRemove("GET", "baabd/approval-templates");

      await this.$axios.get("baabd/approval-templates").then((res) => {
        this.approval_templates = res.data.data.approval_templates;
        this.loading = false;
      });
    },

    async createDocument() {
      if (typeof this.approval_templates[this.selectedDocument] === "undefined") return;

      this.loading_create = true;
      this.showLoadingOverlay(true);

      var d = this.approval_templates[this.selectedDocument];
      var formData = new FormData();
      formData.append("approval_template_id", d.id);
      await this.$axios
        .post("baabd/create-document", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.redirect("Finance.Baabd.Edit", { id: resData.data.id });
          }
          this.loading_create = false;
          this.showLoadingOverlay(false);
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading_create = false;
          this.showLoadingOverlay(false);
        });
    },
  },

  mounted() {
    // this.getData();
  },
};
</script>
